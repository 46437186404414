<template>
	<h1>Side Navigation</h1>

	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre v-highlightjs><code class="language-javascript">import TheNavSide from '@/components/TheNavSide'

export default {
	components: {
		TheNavSide,
	},
	data() {
		return {
			navItems: [
				{
					display: 'Regular Link'
					name: 'regularLink'			// Router name
				},
				{
					display: 'Parent One',
					children: [
						{
							display: 'Child 1',
							name: 'ChildOne',	// Router name
						},
						{
							display: 'Child 2',
							name: 'ChildTwo',	// Router name
						},
					],
				}
			]
		}
	}
}</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>Implementation</h3>
		<ul>
			<li>First implement all pages in the router.</li>
			<li>Then create a Layout page that implements the navigation.</li>
			<li>Make sure to respect the #page / BaseSideNav / #body structure.</li>
		</ul>
	</div>

	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		See implementation of Doc section:<br />
		<i>Admin &rsaquo; Doc &rsaquo; Layout.vue</i>
	</div>
</template>

<script>
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocComponentsSideNav',
	components: {},
	setup() {
		injectMetaData({
			title: 'Doc: Side Nav Component',
		})
	},
	data() {
		return {
			html: [
				'<div id="page">',
				'	<!-- Sidenav -->',
				'	<TheNavSide :items="navItems">',
				'		<template v-slot:top>',
				'			<h1>Demo</h1>',
				'		</template>',
				'	</TheNavSide>',
				'',
				'	<!-- Body -->',
				'	<div id="body">',
				'		<router-view />',
				'	</div>',
				'</div>',
			].join('\n'),
		}
	},
	methods: {},
}
</script>

<style scoped lang="scss"></style>
